import { atom } from "jotai";

import type { Variant } from "./types";

export interface HamburgerMenu {
  isOpen: boolean;
  variant?: Variant | undefined;
}

export const hamburgerMenuAtom = atom<HamburgerMenu>({
  isOpen: false,
});
